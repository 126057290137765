<template>
  <div class="main d-block">
    <div class="title d-flex">
      <div>
        <h2>{{ $t("about.title") }}</h2>
      </div>
      <div v-if="profile.first_role === 'super-admin'" class="pl-3 pb-1">
        <el-tooltip
          :enterable="false"
          content="編輯 “關於我們”"
          placement="top"
          popper-class="tooltipColor"
        >
          <el-button type="primary" size="mini" @click="editFaq">
            <i class="el-icon-edit"></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <div v-for="(label, index) in getFaqs.faq_labels" :key="index">
      <el-row class="mb-3">
        <div class="d-flex">
          <div>
            <h2 style="text-align:left" class="pl-3">
              {{ helper.displayI18nText($i18n.locale, label.label) }}
            </h2>
          </div>
          <div
            v-if="profile.first_role === 'super-admin'"
            class="pl-3 pt-2"
            style="padding-bottom: 5px;"
          >
            <el-tooltip
              :enterable="false"
              :content="'編輯 “' + label.label.tw + '”'"
              placement="top"
              popper-class="tooltipColor"
            >
              <el-button
                type="primary"
                size="mini"
                @click="editCategory(label)"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <el-row :gutter="12" style="height:100px">
          <el-col
            :span="24"
            :sm="12"
            :md="8"
            v-for="(topic, index) in label.topics"
            :key="index"
            class="pl-3 pr-3 pt-3"
          >
            <router-link
              class="routerLink"
              @click.native="handleRouterLink(topic, label)"
              :to="{
                name: 'FaqChild',
                params: {
                  faqs: topic.faqs,
                  id: handleRouterName(topic.title.en),
                  label_id: topic.faq_label_id
                }
              }"
            >
              <div class="cardBox">
                <div class="d-block">
                  <h2 class="mainColor">{{ helper.displayI18nText($i18n.locale, topic.title) }}</h2>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </el-row>
      <br />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  computed: {
    ...mapGetters("faq", ["getFaqs"]),
    ...mapState("user", ["profile"]),
    helper() {
      return helper;
    }
  },
  async created() {
    await this.$store.dispatch("faq/getFaqs");
  },

  methods: {
    handleRouterName(str) {
      return str.replace(/\s+/g, "-").toLowerCase();
    },
    async handleRouterLink(topic, label) {
      await this.$store.dispatch("faq/saveCurrentLabel", label);
      await this.$store.dispatch("faq/saveCurrentTopic", topic);
    },
    editFaq() {
      this.$router.push({
        name: "EditFaqMain"
      });
    },
    editCategory(label) {
      this.$store.dispatch("faq/saveCurrentLabel", label);
      this.$router.push({
        name: "EditFaqCategory"
      });
    }
  }
};
</script>

<style scoped>
.main {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
}
.title {
  align-items: center;
  justify-content: center;
}
.routerLink {
  text-decoration: none;
}

.cardBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757575;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.08);
  height: 140px;
}
.cardBox p {
  margin: 0;
}
.cardBox:hover {
  background-color: #eeeeee;
}
.mainColor {
  color: #42a16a;
}
.el-button {
  outline: none;
}

@media screen and (max-width: 768px) {
  .main{
    width: 100%;
    padding: 20px 15px 0;
  }
}
</style>
