var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main d-block"},[_c('div',{staticClass:"title d-flex"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("about.title")))])]),(_vm.profile.first_role === 'super-admin')?_c('div',{staticClass:"pl-3 pb-1"},[_c('el-tooltip',{attrs:{"enterable":false,"content":"編輯 “關於我們”","placement":"top","popper-class":"tooltipColor"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.editFaq}},[_c('i',{staticClass:"el-icon-edit"})])],1)],1):_vm._e()]),_vm._l((_vm.getFaqs.faq_labels),function(label,index){return _c('div',{key:index},[_c('el-row',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('h2',{staticClass:"pl-3",staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, label.label))+" ")])]),(_vm.profile.first_role === 'super-admin')?_c('div',{staticClass:"pl-3 pt-2",staticStyle:{"padding-bottom":"5px"}},[_c('el-tooltip',{attrs:{"enterable":false,"content":'編輯 “' + label.label.tw + '”',"placement":"top","popper-class":"tooltipColor"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.editCategory(label)}}},[_c('i',{staticClass:"el-icon-edit"})])],1)],1):_vm._e()]),_c('el-row',{staticStyle:{"height":"100px"},attrs:{"gutter":12}},_vm._l((label.topics),function(topic,index){return _c('el-col',{key:index,staticClass:"pl-3 pr-3 pt-3",attrs:{"span":24,"sm":12,"md":8}},[_c('router-link',{staticClass:"routerLink",attrs:{"to":{
              name: 'FaqChild',
              params: {
                faqs: topic.faqs,
                id: _vm.handleRouterName(topic.title.en),
                label_id: topic.faq_label_id
              }
            }},nativeOn:{"click":function($event){return _vm.handleRouterLink(topic, label)}}},[_c('div',{staticClass:"cardBox"},[_c('div',{staticClass:"d-block"},[_c('h2',{staticClass:"mainColor"},[_vm._v(_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, topic.title)))])])])])],1)}),1)],1),_c('br')],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }